<template>
  <v-container :fluid="$vuetify.breakpoint.mdAndDown"
    class="px-10"
  >
    <v-row>
      <v-col>
        <h1 class="mt-5">店舗管理情報</h1>
        <banner-hint>
          店舗管理に関する情報を入力します。
        </banner-hint>
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="12"
        class="pt-5 mb-5"
      >
        <v-card
          class="px-10 py-7"
          elevation="1"
        >
          <icon-info icon="alert" class="mb-10" :square="true">
            更新できない情報に関しては必要書類を確認の上、システム運営側にて変更します。<br />
            必要の際はお手数ですがお問い合わせページからご連絡ください。
          </icon-info>

          <v-form ref="form">
            <v-row>
              <!-- 氏名 -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="contact.contact_name"
                  label="店舗責任者様の氏名"
                  counter="20"
                  required
                  :rules="[valiRules.required, valiRules.max20]"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="contact.contact_name_kana"
                  label="氏名（かな）"
                  counter="30"
                  required
                  :rules="[valiRules.required, valiRules.max30]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- 電話 -->
              <v-col cols="12" md="4">
                <v-text-field
                  v-model.trim="contact.phone"
                  label="店舗責任者様の電話番号"
                  counter="20"
                  required
                  :rules="[valiRules.required, valiRules.phone, valiRules.max20]"
                ></v-text-field>
              </v-col>
              <!-- email -->
              <v-col cols="12" md="8">
                <v-text-field
                  v-model.trim="contact.email"
                  label="店舗責任者様のメールアドレス"
                  counter="100"
                  hint="アカウントIDのメールアドレスとは別のものを使用してください"
                  required
                  :rules="[valiRules.required, valiRules.email, valiRules.max100]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- 住所 -->
              <v-col cols="12">
                <v-text-field
                  v-model.trim="contact.office_address"
                  label="事務所の住所"
                  hint="営業届出確認書記載の事務所の所在地を記載してください"
                  counter="100"
                  required
                  :rules="[valiRules.required, valiRules.max100]"
                ></v-text-field>
              </v-col>
            </v-row>

            <v-row>
              <!-- 届出書 -->
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="contact.license_number"
                  filled readonly
                  label="風俗営業届出確認書の登録番号"
                  required
                  :rules="[valiRules.required, valiRules.max20]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="contact.license_issuer"
                  filled readonly
                  label="届出確認書発行委員会"
                  placeholder="東京都公安委員会"
                  required
                  :rules="[valiRules.required, valiRules.max20]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col>
              <v-col cols="12" md="6">
                <v-text-field
                  v-model.trim="contact.jurisdiction_body"
                  filled readonly
                  label="管轄警察署"
                  required
                  :rules="[valiRules.required, valiRules.max20]"
                  @click="unableToEdit"
                ></v-text-field>
              </v-col>
            </v-row>

            <!-- ボタン -->
            <v-row no-gutters justify="end" class="mt-3">
              <v-btn
                depressed
                color="primary"
                @click="submitted()"
              >更新</v-btn>
            </v-row>

          </v-form>
        </v-card>
      </v-col>
    </v-row>

    <!-- ローダー -->
    <loader
      :loading="loading"
      :absolute="false"
    >
      {{ loadingMessage }}
    </loader>

    <!-- バリデーションアラート -->
    <v-snackbar
      v-model="snackbar.open"
      :timeout="3000"
      :color="snackbar.color"
      top
    >
      {{ snackbar.message }}
    </v-snackbar>
  </v-container>
</template>

<!-- ************************************* -->
<!-- ************* スクリプト ************** -->
<!-- ************************************* -->
<script>
import $literals from '@/literals.js'
import { ApiTool, CheckTokenError, ValidationRules } from '@/module.js'
import Loader from '@/components/_Loader.vue'
import BannerHint from "@/components/_BannerHint.vue"
import IconInfo from "@/components/_IconInfo.vue"

export default {
  components: {
    'loader': Loader,
    'banner-hint': BannerHint,
    'icon-info': IconInfo,
  },

  props: {
    apiAdmin: {
      type: String,
      required: true
    },
    shopData: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      contact: {},
      loading: false,
      loadingMessage: '',
      valiRules: ValidationRules,
      snackbar: {open: false, color: 'primary', message: ''},
      adminApi: new ApiTool(this.apiAdmin, this.shopData),
    }
  },

  computed: {
    serverToken() {
      return sessionStorage.getItem('serverToken')
    },
  },

  created() {
    this.adminApi.setToken(this.serverToken)

    this.getData()
    .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
  },

  methods: {
    async getData() {
      this.loading = true
      this.loadingMessage = 'データ取得中・・・'

      await Promise.all([
        this.adminApi.getReqWithAuth('admin-info/').then( data => {
          if (!data) return
          this.contact = data
        }),
      ])

      this.loading = false
    },

    unableToEdit() {
      this.snackbar = {...{color:'warning', message: $literals.MESSAGE.warningShopInfoUnableToEdit, open: true}}
    },

    //**************************************************
    //**************************************************
    //                    APIコール
    //**************************************************
    //**************************************************
    //更新
    //**************************************************
    submitted() {
      if (!this.$refs['form'].validate()) {
        this.snackbar = {...{color:'warning', message: $literals.MESSAGE.validationFormInput, open: true}}
        return
      }

      this.loading = true
      this.loadingMessage = 'データ更新中・・・'

      const apiPath = 'update/admin-info/'
      const formData = new FormData()
      formData.append('contact_name', this.contact.contact_name)
      formData.append('contact_name_kana', this.contact.contact_name_kana)
      formData.append('phone', this.contact.phone)
      formData.append('email', this.contact.email)
      formData.append('office_address', this.contact.office_address)
      formData.append('license_number', this.contact.license_number)
      formData.append('license_issuer', this.contact.license_issuer)
      formData.append('jurisdiction_body', this.contact.jurisdiction_body)

      this.adminApi.apiReqWithData('PUT', apiPath, formData).then(() => {
        this.snackbar = {...{color:'success', message: $literals.MESSAGE.successUpdateSubmit, open: true}}
      })
      .catch(error => { if (CheckTokenError(error)) this.$emit('reset') })
      .then(() => this.loading = false )
    },
  }
}
</script>

<style scoped>
</style>
